<template>
  <div class="req-temp-container">
    <!-- Payment Request Template List card starts here -->

    <vs-card class="mt-6">
      <div slot="header">
        <div class="flex items-center">
          <h3 class="font-medium">Linked systems</h3>
          
        </div>
      </div>
      
      <vs-table :data="linkRequests" class="mt-5 stripes">
        <template slot="thead">
          <vs-th> Name </vs-th>
          <vs-th> Status</vs-th>
          <vs-th></vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.introducerId.name">
              {{ tr.introducerId.name }}
            </vs-td>
            <vs-td :data="tr.status">
              <span :class="changeStatusColor(tr.status)"> {{ tr.status }}</span>
            </vs-td>
            <vs-td :data="tr">
              <a class="pl-5 pr-5" @click="viewLinkedSystem(tr._id)">View</a>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      linkRequests: [],
    };
  },
  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },
    partnerId() {
      return this.user.userType === "admin"
        ? this.user._id
        : this.user.partnerId
    },
  },
  mounted() {
    this.merchantId =
      JSON.parse(localStorage.user).userType == "admin"
        ? JSON.parse(localStorage.user).merchantId
        : JSON.parse(localStorage.user).partner.merchantId;
    this.getLinkRequestByMerchantId();
  },
  methods: {
    ...mapActions("linkRequest", ["fetchLinkRequestByMerchantId", "fetchRequestDetailById"]),

    async getLinkRequestByMerchantId() {
      await this.fetchLinkRequestByMerchantId(this.partnerId).then((res) => {
        this.linkRequests = res.data.data;
      }).catch(err=> {
        this.$vs.notify({
          title: "Link Request",
          text: err.response.data.message,
          color: "danger",
          icon:"error",
          position:"top-right",
        });
        if(err.response.status === 400) {
          this.$router.push({name:'settings'});
        }
      });
    },
    async viewLinkedSystem(id) {
      this.$router.push({
        name: "linked-system-detail",
        params: { id: id },
      });
    },
    changeStatusColor(status){
      let bgClass = "";
      if(status == "Rejected"){
        bgClass = "badge danger"
      }else if(status == "Connected"){
          bgClass = "badge success";
      }else if(status == "Pending"){
          bgClass = "badge pending";
      }
      else{
        bgClass = "badge primary";
      }
      return bgClass;
    },
  },
};
</script>

